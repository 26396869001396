<template>
  <div
    style="box-shadow: 0px 4px 25px 0px #0000000d"
    class="mx-auto w-full max-w-2xl pb-4 bg-white rounded-2xl overflow-hidden border border-outlined flex flex-col"
  >
    <div class="border-b border-outlined p-4 pt-12 relative">
      <div
        v-if="step > 1"
        class="absolute cursor-pointer top-12 left-10 flex rounded-full h-10 w-10 bg-newAsh items-center justify-center"
        @click="
          step--;
          emit('step', step);
        "
      >
        <img src="@/assets/icons/chevron-left.svg" alt="" />
      </div>

      <h3 class="text-center text-lg md:text-3xl font-semibold">
        {{
          step == 1
            ? "Client Information"
            : step == 2
            ? `${receipt ? "Receipt" : "Invoice"} Details`
            : "Additional information"
        }}
      </h3>
    </div>
    <div v-if="step == 1" class="w-full py-4 flex flex-col">
      <div class="px-5 md:px-10 grid items-center grid-cols-7 gap-4">
        <easiSelectInput2
          :options="allClients"
          :bookKeeping="true"
          :value="args.client"
          @update="handleUpdateClient"
          placeholder="Select Client *"
          name="client"
          class="col-span-5"
          :error="errorRules.client"
        >
          <div>
            <svg
              v-if="!gettingClients"
              class="fill-current h-4 w-4"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
            >
              <path
                d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"
              />
            </svg>

            <div v-else>
              <div role="status">
                <svg
                  aria-hidden="true"
                  class="w-8 h-8 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600"
                  viewBox="0 0 100 101"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                    fill="currentColor"
                  />
                  <path
                    d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                    fill="currentFill"
                  />
                </svg>
                <span class="sr-only">Loading...</span>
              </div>
            </div>
          </div></easiSelectInput2
        >
        <div
          class="col-span-2 mt-1 h-14 py-1"
          :class="errorRules.client ? ' mb-6' : ''"
        >
          <div
            class="gap-2 cursor-pointer border border-primary h-full w-full rounded-full flex items-center justify-center"
            @click="showClientDetails = true"
          >
            <i class="pi pi-plus text-sm" /> Add New Client
          </div>
        </div>
      </div>

      <div v-if="showClientDetails" class="px-5 md:px-10">
        <easiTextInput
          label="First name"
          placeholder="First Name"
          name="firstName"
          class=""
          v-model="args.firstName"
          :error="errorRules.firstName"
        />
        <easiTextInput
          label="Last name"
          placeholder="Last Name"
          name="lastName"
          class=""
          v-model="args.lastName"
          :error="errorRules.lastName"
        />
        <easiTextInput
          label="Email"
          placeholder="Email Address"
          name="email"
          class=""
          v-model="args.email"
          :error="errorRules.email"
        />
        <easiTextInput
          label="Phone Number"
          placeholder="Phone Number"
          name="phone"
          class=""
          v-model="args.phone"
          :error="errorRules.phone"
        />
      </div>

      <div class="px-5 md:px-10">
        <easiTextInput
          label="Name of Company"
          placeholder="Name of Company"
          name="name"
          class=""
          v-model="args.companyName"
          :error="errorRules.companyName"
        />
      </div>

      <hr class="my-6" />
      <div class="px-5 md:px-10">
        <div class="text-grey font-medium text-base">Billing Address</div>
        <easiTextArea
          label="Street Address *"
          placeholder="Street Address *"
          name="address"
          v-model="args.address"
          :error="errorRules.address"
        />

        <div class="grid grid-cols-2 gap-4 mt-2">
          <easiSelectInput2
            :options="[{ label: 'Nigeria', value: 'Nigeria' }]"
            :value="args.country"
            @update="args.country = $event"
            placeholder="Select Country"
            name="country"
            class="mt-3"
            :bookKeeping="true"
            :error="errorRules.country"
          ></easiSelectInput2>

          <easiSelectInput2
            :options="allStates"
            :value="args.state"
            @update="args.state = $event"
            placeholder="Select State"
            name="country"
            class="mt-3"
            :bookKeeping="true"
            :error="errorRules.state"
          ></easiSelectInput2>
        </div>
        <div class="grid grid-cols-2 gap-4 mt-2">
          <easiTextInput
            label="city"
            placeholder="City *"
            name="city"
            v-model="args.city"
            :error="errorRules.city"
          />
          <easiTextInput
            label="Postal"
            placeholder="Postal Code"
            name="postal"
            v-model="args.postal"
            :error="errorRules.postal"
          />
        </div>
        <!-- <div
        v-if="args.phone.length>10"
        class="flex items-center mt-5">
          <input
            :checked="args.whatsapp"
            :value="false"
            @change="args.whatsapp = !args.whatsapp"
            class="mr-2 my-2 border-2 border-dark-800"
            type="checkbox"
            name="whatsapp"
            id=""
          />
          <label
            @click="args.whatsapp = !args.whatsapp"
            for="whatsapp"
            class="text-grey text-sm font-medium"
            >Send to WhatsApp</label
          >
        </div> -->
        <div class="mt-8">
          <easiButton :loading="loading" @click="handleSave" block
            >Save and Continue</easiButton
          >
        </div>
      </div>
    </div>

    <div v-else-if="step == 2" class="w-full py-4 flex flex-col">
      <div
        v-if="items.length"
        class="bg-newLightGreen max-h-64 overflow-auto py-7 px-12"
      >
        <div
          class="grid grid-cols-6 text-center bg-dirtyGreen py-2 px-4 rounded-lg mb-2"
        >
          <div class="col-span-2">ITEM</div>
          <div class="col-span-1">QTY</div>
          <div class="col-span-1">UNIT</div>
          <div class="col-span-2">AMOUNT</div>
        </div>
        <ul class="flex flex-col gap-2">
          <li v-for="(item, index) in items" :key="index" class="relative">
            <div
              class="grid grid-cols-6 bg-white items-center rounded-2xl text-center px-6"
            >
              <div class="col-span-2 break-all capitalize">
                {{ item.itemName }}
              </div>
              <div class="col-span-1 border-l border-r border-outlineGray py-3">
                {{ item.quantity }}
              </div>
              <div class="col-span-1 border-r border-outlineGray py-3">
                {{ item.unit }}
              </div>
              <div class="col-span-2">{{ formatAmount(item.amount) }}</div>
            </div>

            <div
              @click="removeItem(index)"
              class="absolute -right-8 top-1/2 transform -translate-y-1/2"
            >
              <img src="@/assets/icons/remove-item.svg" alt="" />
            </div>
          </li>
        </ul>
      </div>

      <div class="px-5 md:px-10">
        <p v-if="items.length" class="my-4 font-medium text-xl">Add New Item</p>
        <easiSelectInput2
          :options="allItems"
          :bookKeeping="true"
          :value="args.item"
          @update="handleUpdateItem"
          placeholder="Select Item"
          name="client"
          class="col-span-5"
          :error="errorRules.item"
        ></easiSelectInput2>
        <div class="grid grid-cols-2 gap-4 mt-2">
          <easiTextInput
            label="Quantity"
            placeholder="Quantity"
            name="Quantity"
            v-model="args.quantity"
            type="number"
            :error="errorRules.quantity"
          />
          <easiTextInput
            label="Unit price"
            placeholder="Unit price"
            name="Unit price"
            format
            disabled
            v-model="args.unitPrice"
            :error="errorRules.unitPrice"
          />
        </div>
        <easiTextInput
          label="Amount"
          placeholder="Amount"
          name="Amount"
          v-model="unitAmount"
          format
          disabled
          :error="errorRules.amount"
        />

        <div class="flex justify-start mt-5">
          <div
            class="flex gap-2 items-center cursor-pointer font-bold"
            @click="AddAnother"
          >
            <i class="pi pi-plus text-sm" />
            {{ !items.length ? "Add Item" : "Add Item" }}
          </div>
        </div>
        <div v-if="subTotal" class="mt-11 flex flex-col gap-2">
          <p>Sub total</p>
          <div
            class="rounded-xl bg-newLimeGreen text-grey text-2xl font-medium px-6 py-2"
          >
            NGN{{
              unitAmount
                ? (unitAmount + subTotal).toLocaleString()
                : subTotal.toLocaleString()
            }}
          </div>
        </div>
        <div class="mt-8">
          <easiButton :loading="loading" @click="handleSave2" block
            >Save and Continue</easiButton
          >
        </div>
      </div>
    </div>

    <div v-else-if="step == 3" class="w-full py-4 flex flex-col">
      <div class="px-5 md:px-10">
        <p class="text-secondary font-medium text-sm italic">
          All fields are optional
        </p>
        <easiTextInput
          label="Discount"
          placeholder="Discount"
          name="discount"
          format
          v-model="args.discount"
        />
        <easiTextInput
          label="Delivery fee"
          placeholder="Delivery fee"
          name="Delivery fee"
          format
          v-model="args.fee"
        />
        <easiSelectInput2
          :options="[{ label: 'VAT- 7.5%', value: 7.5 }]"
          :value="args.tax"
          @update="updateTax"
          placeholder="Tax"
          name="tax"
          class="mt-4"
          :bookKeeping="true"
        ></easiSelectInput2>
        <easiTextArea
          :label="`${receipt ? 'Receipt' : 'Invoice'} Note`"
          :placeholder="`${receipt ? 'Receipt' : 'Invoice'} Note`"
          name="note"
          v-model="args.note"
        />
        <div v-if="subTotal" class="mt-11 flex flex-col gap-2">
          <p>Total</p>
          <div
            class="rounded-xl bg-newLimeGreen text-grey text-2xl font-medium px-6 py-2"
          >
            NGN{{ Total.toLocaleString() }}
          </div>
        </div>

        <div class="mt-8">
          <easiButton :loading="loading" @click="preViewInvoice" block
            >Preview
            {{ receipt ? "Receipt" : "Invoice" }}
          </easiButton>
        </div>
      </div>
    </div>
  </div>

  <easiAlert
    v-if="isAlert"
    :caption="`Your product has been ${
      ediMode ? 'updated' : 'added'
    } successfully`"
  >
    <div class="pb-4 px-6">
      <easiButton size="medium" :loading="loading" @click="closeAlert" block
        >Okay!</easiButton
      >
    </div>
  </easiAlert>
</template>

<script setup>
import { reactive, ref, computed, onMounted } from "vue";
import { useRouter } from "vue-router";
import { helperFunctions } from "@/composable/helperFunctions";
import { useDataStore } from "@/stores/data.js";
import { useToast } from "vue-toastification";
import { getClients } from "@/services/book-keeping/client";
import { getInventoryItems } from "@/services/book-keeping/inventory";
const store = useDataStore();
const router = useRouter();
const toast = useToast();
const { formatAmount } = helperFunctions;

const allItems = computed(() => {
  return store.getBookingInventory
    ? store.getBookingInventory.docs.map((each) => ({
        label: `${each.name}`,
        value: each._id,
      }))
    : [];
});
const handleUpdateItem = (e) => {
  const selectedItem = store.getBookingInventory.docs.find(
    (each) => each._id === e
  );

  args.item = e;
  args.itemName = selectedItem.name;
  // args.quantity = selectedItem.quantity;
  args.unitPrice = selectedItem.sellingPrice;
};
const storeBookKeepingClients = computed(
  () => store.getBookkeepingClients.docs || []
);
const allClients = computed(() => {
  return storeBookKeepingClients.value.map((each) => ({
    label: `${each.firstName} ${each.lastName}`,
    value: each._id,
  }));
});

const handleUpdateClient = (e) => {
  const selectedClient = storeBookKeepingClients.value.find(
    (each) => each._id === e
  );
  args.client = e;
  (args.email = selectedClient.email),
    (args.phone = selectedClient.phone),
    (args.firstName = `${selectedClient.firstName} `);
  args.lastName = `${selectedClient.lastName} `;
  args.companyName = selectedClient.companyName;
  args.address = selectedClient.address;
  showClientDetails.value = true;
};

const step = ref(1);
const validateForm = () => {
  clearErrors();
  if (
    !args.client.trim() &&
    !args.firstName.trim() &&
    !args.lastName.trim() &&
    !showClientDetails.value
  ) {
    errorRules.client = "Client is required";
    return false;
  }
  if (!args.firstName.trim()) {
    errorRules.firstName = "first name is required";
    return false;
  } else if (!args.lastName.trim()) {
    errorRules.lastName = "last name is required";
    return false;
  } else if (!args.address) {
    errorRules.address = "Street Address is required";
    return false;
  } else if (!args.country) {
    errorRules.country = "country is required";
    return false;
  } else if (!args.state) {
    errorRules.state = "state is required";
    return false;
  } else if (!args.city) {
    errorRules.city = "City is required";
    return false;
  }

  clearErrors();

  return true;
};
const gettingClients = ref(false);
onMounted(async () => {
  try {
    allClients.value.length ? "" : (gettingClients.value = true);
    await getClients();
    getInventoryItems();
  } catch (error) {
    console.log(error);
  } finally {
    gettingClients.value = false;
  }
});
const items = ref([]);
const updateTax = (e) => {
  if (args.tax === e) {
    args.tax = "";
  } else {
    args.tax = e;
  }
};
const AddAnother = () => {
  if (!args.item)
    return toast.error("please select at least 1 item from your inventory");
  console.log("args is", args);
  const newItem = {
    id: args.item,
    itemName: args.itemName,
    quantity: args.quantity,
    unit: args.unitPrice,
    amount: unitAmount.value, // initial amount = quantity * unit
  };

  items.value.push(newItem);
  args.item = "";
  args.itemName = "";
  args.quantity = "";
  args.unitPrice = "";
};
const removeItem = (index) => {
  items.value.splice(index, 1);
};
const subTotal = computed(() => {
  return items.value.reduce((total, item) => total + item.amount, 0);
});
const totalTax = computed(() => {
  if (args.tax) {
    return subTotal.value * (Number(args.tax) / 100);
  } else {
    return 0;
  }
});
const Total = computed(() => {
  return (
    subTotal.value +
    (Number(args.fee) || 0) +
    totalTax.value -
    (args.discount || 0)
  );
});
const props = defineProps({
  editMode: {
    type: Boolean,
    default: false,
  },
  receipt: {
    type: Boolean,
    default: false,
  },
});
const emit = defineEmits(["step", "preview"]);

const showClientDetails = ref(false);

const allStates = ref([
  { label: "Abia", value: "abia" },
  { label: "Adamawa", value: "adamawa" },
  { label: "Akwa Ibom", value: "akwa_ibom" },
  { label: "Anambra", value: "anambra" },
  { label: "Bauchi", value: "bauchi" },
  { label: "Bayelsa", value: "bayelsa" },
  { label: "Benue", value: "benue" },
  { label: "Borno", value: "borno" },
  { label: "Cross River", value: "cross_river" },
  { label: "Delta", value: "delta" },
  { label: "Ebonyi", value: "ebonyi" },
  { label: "Edo", value: "edo" },
  { label: "Ekiti", value: "ekiti" },
  { label: "Enugu", value: "enugu" },
  { label: "Gombe", value: "gombe" },
  { label: "Imo", value: "imo" },
  { label: "Jigawa", value: "jigawa" },
  { label: "Kaduna", value: "kaduna" },
  { label: "Kano", value: "kano" },
  { label: "Katsina", value: "katsina" },
  { label: "Kebbi", value: "kebbi" },
  { label: "Kogi", value: "kogi" },
  { label: "Kwara", value: "kwara" },
  { label: "Lagos", value: "lagos" },
  { label: "Nasarawa", value: "nasarawa" },
  { label: "Niger", value: "niger" },
  { label: "Ogun", value: "ogun" },
  { label: "Ondo", value: "ondo" },
  { label: "Osun", value: "osun" },
  { label: "Oyo", value: "oyo" },
  { label: "Plateau", value: "plateau" },
  { label: "Rivers", value: "rivers" },
  { label: "Sokoto", value: "sokoto" },
  { label: "Taraba", value: "taraba" },
  { label: "Yobe", value: "yobe" },
  { label: "Zamfara", value: "zamfara" },
]);

const isAlert = ref(false);
const loading = ref(false);

const uploadFile = ref(null);
const unitAmount = computed(() => args.quantity * args.unitPrice);
const args = reactive({
  client: "",
  companyName: "",
  address: "",
  city: "",
  postal: "",
  country: "",
  state: "",
  email: "",
  phone: "",
  whatsapp: false,
  item: "",
  itemName: "",
  quantity: "",
  note: "",
  fee: "",
  lastName: "",
  firstName: "",
  tax: "",
  unitPrice: "",
  amount: "",

  quantityInStock: "",

  expiryDate: "",
  costPrice: "",
  weight: "",
  stockNumber: "",
  imgUrl: "",
});

const errorRules = reactive({
  companyName: false,
  address: false,
  client: "",
  country: false,
  state: false,
  city: false,
  postal: false,
  email: false,
  phone: false,
  item: false,
  quantity: false,
  unitPrice: false,
  amount: false,
  firstName: false,
  lastName: false,

  quantityInStock: false,
  client: false,

  expiryDate: false,
  costPrice: false,
  weight: false,
  stockNumber: false,
  imgUrl: false,
});

const clearFields = () => {
  Object.keys(args).map((key) => {
    args[key] = "";
  });
  Object.keys(errorRules).map((key) => {
    args[key] = false;
  });
};

const clearErrors = () => {
  Object.keys(errorRules).map((key) => {
    errorRules[key] = false;
  });
};

const handleClose = () => {
  clearFields();
  router.go(-1);
};

const closeAlert = () => {
  handleClose();
  isAlert.value = false;
};
const handleSave = () => {
  if (!validateForm()) return;
  step.value = 2;
  emit("step", 2);
};
const handleSave2 = () => {
  // if there is data on the formatDate, push it to the items array and clear it
  if (args.item && args.itemName && args.quantity && args.unitPrice) {
    AddAnother();
  }

  if (!items.value.length)
    return toast.error("Please add an item from your inventory");
  step.value = 3;
  emit("step", 3);
};
const preViewInvoice = () => {
  if (Total.value < 0)
    return toast.error("Total value must be greater than zero");

  emit("preview", { ...args, items: items.value, total: Total.value });
};
</script>

<style></style>
